<template>
  <div class="list">
    <div class="tip">
      <p>温馨提示：请在投保前阅读并确认以下内容</p>
    </div>
    <div
      class="item"
      v-for="(item, index) in list"
      :key="index"
      @click="goTo(item)"
    >
      <p class="name">{{ item.name }}</p>
      <div class="right">
        <img src="../assets/arrowleft-icon.png" alt="" />
      </div>
    </div>
    <div class="btn" @click="fileList">
      <p>我已阅读并同意以上内容</p>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      list: [
        {
          id: "1",
          name: "责任免除",
          key: "zeRenMianChu",
        },
        {
          id: "2",
          name: "特别约定",
          key: "teBieYueDing",
        },
        {
          id: "3",
          name: "个人信息授权、投保说明",
          key: "geRenXinXiShouQuan",
        },
      ],
    };
  },
  methods: {
    //跳转保险条款
    fileList() {
      this.$router.push({
        path: "/fileList",
        query: {
          channel: "technologicalProcess",
          type: "productTerms",
        },
      });
    },
    goTo({ key }) {
      this.$router.push({
        path: `${key}/word`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.bold {
  font-weight: bold !important;
  color: #000 !important;
}
.tip {
  width: 100%;
  height: 44px;
  background: #fff7f4;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    font-size: 14px;
    font-weight: 400;
    color: $primary-color;
    line-height: 21px;
  }
}
.msg {
  margin-top: 16px;
  padding: 0 20px;
  padding-bottom: 100px;
  p {
    font-size: 15px;
    font-weight: 400;
    color: #333333;
    line-height: 24px;
    text-align: justify;
  }
}
.btn {
  width: 100%;
  height: 90px;
  background: #fff;
  position: fixed;
  z-index: 1000;
  left: 0;
  bottom: 0;
  p {
    width: 234px;
    height: 45px;
    margin: 0 auto;
    background: $primary-color;
    border-radius: 23px;
    font-size: 15px;
    font-weight: bold;
    color: #ffffff;
    line-height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
  }
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 17px 20px;
  .name {
    margin-left: 12px;
    flex: 1;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 20px;
    text-align: justify;
  }
  .right {
    img {
      width: 10px;
    }
  }
}
</style>